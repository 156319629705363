import React, { useEffect, useState } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import { useMathJax } from '../contexts/MathJaxContext';

const RichText = ({ content, className, autoTypeset = true }) => {
    const [mounted, setMounted] = useState(false);
    const { typesetContent } = useMathJax();
    const config = {
        tex: {
            inlineMath: [['$', '$'], ['\\(', '\\)']],
        },
        options: {
            ignoreHtmlClass: 'w-e-text-container',
            processHtmlClass: 'rich-text',
        },
        startup: {
            typeset: true
        }
    };

    useEffect(() => {
        setMounted(true);
        
        typesetContent();
    }, [content, typesetContent]);

    return (
        <MathJaxContext config={config}>
            <MathJax hideUntilTypeset={'first'}>
                <div 
                    key={mounted ? 'mounted' : 'unmounted'}
                    className={`rich-text ${className || ''}`} 
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            </MathJax>
        </MathJaxContext>
    );
};

export default RichText;