import React, { createContext, useContext, useCallback } from 'react';
import debounce from 'lodash/debounce';

const MathJaxContext = createContext();

export const MathJaxProvider = ({ children }) => {
    const typesetContent = useCallback(
      debounce(() => {
        if (window.MathJax) {
          try {
            window.MathJax.typesetPromise && window.MathJax.typesetPromise();
          } catch (e) {
            console.error('MathJax typeset error:', e);
          }
        }
    }, 300),
      []
    );

    return (
        <MathJaxContext.Provider value={{ typesetContent }}>
            {children}
        </MathJaxContext.Provider>
    );
};

export const useMathJax = () => {
    const context = useContext(MathJaxContext);
    if (!context) {
        throw new Error('useMathJax must be used within a MathJaxProvider');
    }
    return context;
}; 